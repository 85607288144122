<template>
  <v-container>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Timeclock Manager</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip v-if="user.username === 'jon.moon' && filter.timeclocks.length === 1" bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="clearLocation">
              <v-icon>fal fa-calendar-times</v-icon>
            </v-btn>
          </template>
          <span>Clear location</span>
        </v-tooltip>
        <v-btn-toggle v-model="view">
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <v-btn v-on="on" value="chart" disabled style="padding: 0 12px">
                  <v-icon>far fa-chart-line</v-icon>
                </v-btn>
              </span>
            </template>
            <span>Show as Chart; coming soon</span>
          </v-tooltip> -->
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" value="table">
                <v-icon>fal fa-table</v-icon>
              </v-btn>
            </template>
            <span>Show as Table</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" value="monitor">
                <v-icon>fal fa-user-clock</v-icon>
              </v-btn>
            </template>
            <span>Monitor Mode (only show scans as they happen after you turn on the mode)</span>
          </v-tooltip> -->
        </v-btn-toggle>
        <v-btn exact @click="$router.go(-1)">Go Back</v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col>
            <v-autocomplete v-model="filter.timeclocks" :items="timeclockList" label="Timeclock" item-value="_id" item-text="location" multiple outlined clearable @change="loadData" @click:clear="loadData"></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete v-model="filter.student" :items="studentList" label="Student Search"  item-value="pidm" item-text="text" outlined clearable @change="loadData" @click:clear="loadData"></v-autocomplete>
          </v-col>
          <v-col>
            <v-menu :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on }">
                <v-text-field v-on="on" :value="dateRangeText" label="Date Range" hint="Select both start and end dates (can be the same)" persistent-hint readonly outlined clearable @click:clear="clearDates"></v-text-field>
              </template>
              <v-date-picker v-model="filter.dates" :max="yearMonDayFormat(new Date())" no-title scrollable range @change="loadData"></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="view === 'table'">
        <p>A status of C means the shift was added to Banner, P means it is pending (they are either still clocked-in or the shift has not been added to Banner), and R means the shift was rejected for some reason and was not added to Banner. Hold your mouse over any R status to see the reason for why the shift was rejected.</p>
      </v-card-text>
      <v-card-text v-if="view === 'chart'">
        <LineChart :chartData="chartData" :options="chartOptions" :height="600" :width="800"></LineChart>
      </v-card-text>
      <timeclock-table v-else-if="view === 'monitor'" :items="monitorScans"></timeclock-table>
      <timeclock-table v-else-if="view === 'table'" :items="scans" :item-total="itemTotal" :loading="loadingScans" :options="tableOptions" @update="loadScans"></timeclock-table>
    </v-card>
  </v-container>
</template>
<script>
import { computed, onBeforeUnmount, onMounted, reactive, ref, watch } from '@vue/composition-api'
import { yearMonDayFormat } from '../../../helpers/formatters'
import { Line } from 'vue-chartjs/legacy'

export default {
  components: {
    TimeclockTable: () => import('@/components/work-study/timeclockTable'),
    LineChart: Line
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const punchServ = root.$feathers.service('timeclock/punch')
    const setupServ = root.$feathers.service('timeclock/setup')

    const filter = computed({
      get: () => root.$store.state['work-study'].timeclockFilter,
      set: (val) => root.$store.commit('work-study/setTimeclockFilter', val)
    })
    const dateRangeText = computed(() => {
      if (filter.value.dates.length > 0) {
        return filter.value.dates.join(' ~ ')
      }
    })

    const tableOptions = ref({
      page: 1,
      itemsPerPage: 10,
      sortBy: ['inDate'],
      sortDesc: [false]
    })

    const timeclockList = computed(() => root.$store.state['work-study'].timeclocks)
    const studentList = ref([])

    const scans = ref([])
    const itemTotal = ref(0)
    const loadingScans = ref(false)
    async function loadScans (options) {
      loadingScans.value = true
      if (options) {
        for (let l in tableOptions.value) {
          if (l in options) tableOptions.value[l] = options[l]
        }
      }
      const { page, itemsPerPage, sortBy, sortDesc } = tableOptions.value
      const query = {
        $populate: ['in.timeclock', 'out.timeclock'],
        $limit: itemsPerPage,
        $skip: (page - 1) * itemsPerPage,
        $sort: {}
      }
      const { timeclocks, student, dates } = filter.value
      if (timeclocks.length > 0) {
        query.$or = [
          { 'in.timeclock': { $in: timeclocks } },
          { 'out.timeclock': { $in: timeclocks } }
        ]
      }
      if (dates != null && dates.length > 0) {
        if (dates[1] < dates[0]) {
          dates.reverse()
        }
        if (!('$or' in query)) query.$or = [{}, {}]
        const dateRange = { $gte: dates[0] + ' 00:00', $lte: dates[1] + ' 23:59:59' }
        query.$or[0]['in.date'] = dateRange
        query.$or[1]['out.date'] = dateRange
      }
      if (student != null && student !== '') {
        query.pidm = student
      } else {
        query.pidm = { $in: studentList.value.map(({ pidm }) => pidm) }
      }
      for (let i = 0; i < sortBy.length; i++) {
        if (sortBy[i] === 'inDate') {
          query.$sort['in.date'] = sortDesc[i] ? 1 : -1
        } else if (sortBy[i] === 'outDate') {
          query.$sort['out.date'] = sortDesc[i] ? 1 : -1
        } else {
          query.$sort[sortBy[i]] = sortDesc[i] ? 1 : -1
        }
      }
      const { total, data } = await punchServ.find({ query })
      itemTotal.value = total
      for (let i = 0; i < data.length; i++) {
        const { pidm } = data[i]
        const { data: peopleData } = await root.$feathers.service('directory/people').find({ query: { pidm } })
        if (peopleData.length > 0) {
          data.splice(i, 1, { ...data[i], name: peopleData[0].name.last + ', ' + peopleData[0].name.first })
        }
      }
      scans.value = data
      loadingScans.value = false
    }

    watch(filter, () => {
      loadScans()
    })

    onMounted(async () => {
      let dt = new Date()
      let aidy = (dt.getFullYear() % 100) * 101 + 1
      if (dt.getMonth() < 7) aidy -= 101
      const query = { aidy, $limit: 0, $select: ['bannerId', 'name', 'pidm'], $sort: { name: 1 } }
      const isAdmin = root.$store.getters.hasRole(['Technology Services', 'HR', 'Fin Aid', 'Center for Calling and Career'])
      if (!isAdmin) {
        query['placement.supervisor.pidm'] = user.value.pidm
      }
      const { total } = await root.$feathers.service('work-study/students').find({ query })
      query.$limit = 50
      for (let i = 0; i < total; i += 50) {
        query.$skip = i
        const { data } = await root.$feathers.service('work-study/students').find({ query })
        for (const { pidm, name, bannerId } of data) {
          studentList.value.push({ pidm, text: name + ' (' + bannerId + ')' })
        }
      }
      loadScans()
      if (timeclockList.value.length === 0) {
        root.$store.dispatch('work-study/loadTimeclocks')
      }
    })

    const monitorMode = ref(false)
    const monitorScans = ref([])

    const onPatchedListener = async (data) => {
      let include = false
      if ('in' in data && 'timeclock' in data.in) {
        for (const id of filter.value.timeclocks) {
          if (data.in.timeclock === id) include = true
        }
        include = true
      }
      if (!include && 'out' in data && 'timeclock' in data.out) {
        for (const id of filter.value.timeclocks) {
          if (data.out.timeclock === id) include = true
        }
        include = true
      }

      if (include) {
        data.in.timeclock = await setupServ.get(data.in.timeclock)
        if ('out' in data && 'timeclock' in data.out) {
          data.out.timeclock = await setupServ.get(data.out.timeclock)
        }
        for (let i = 0; i < monitorScans.value.length; i++) {
          if (monitorScans.value[i]._id === data._id) {
            monitorScans.value.splice(i, 1, data)
            return
          }
        }
        monitorScans.value.push(data)
      }
    }

    onMounted(() => {
      punchServ.on('created', onPatchedListener)
      punchServ.on('patched', onPatchedListener)
    })

    onBeforeUnmount(() => {
      punchServ.removeListener('created', onPatchedListener)
      punchServ.removeListener('patched', onPatchedListener)
    })

    function clearLocation () {
      if (confirm('Are you sure you want to clear this location?')) {
        const id = filter.value.timeclocks[0]
        setupServ.get(id).then((data) => {
          delete data.ip
          delete data.locked
          delete data.ping
          setupServ.update(id, data).then(() => {
            root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Timeclock location cleared and ready to be reassigned' })
          })
        })
      }
    }

    const view = ref('table')
    const chartOptions = reactive({
      responsive: true,
      plugins: {
        legend: {
          position: 'top'
        },
        title: {
          display: true,
          text: 'Package Trends'
        }
      }
    })
    // const colors = ['red', 'purple', 'indigo', 'teal', 'yellow', 'orange', 'brown', 'pink', 'blue', 'cyan', 'green', 'lime', 'amber', 'grey']
    const chartData = reactive({
      labels: [],
      datasets: []
    })
    async function loadChart () {
      // We are going to have the labels be pre-populated as hours from 12a to 12a
      chartData.labels = ['12-1a', '1-2a', '2-3a', '3-4a', '4-5a', '5-6a', '6-7a', '7-8a', '8-9a', '9-10a', '10-11a', '11a-12p', '12-1p', '1-2p', '2-3p', '3-4p', '4-5p', '5-6p', '6-7p', '7-8p', '8-9p', '9-10p', '10-11p', '11p-12a']
      chartData.datasets = []
      const { timeclocks, student, dates } = filter.value
      if (dates[1] < dates[0]) dates.reverse()
      let [minDate, maxDate] = dates
      const query = { $report: true, minDate, maxDate }
      if (timeclocks.length > 0) query.location = timeclocks
      if (student != null && student !== '') query.pidm = student
      else query.pidm = { $in: studentList.value.map(({ pidm }) => pidm) }

      // const { data } = await punchServ.find({ query })
      const startDate = new Date(dates[0])
      startDate.setHours(0)
      const endDate = new Date(dates[1])
      endDate.setHours(23)

      // let dt = new Date(data[0].date)
      // let set = 0
      // let setDate = data[0].date.substring(0, 10)
      // chartData.datasets[0].label = 'Scans ' + (duration.value === 'Today' ? duration.value : (duration.value === 'Term' ? 'this term' : 'the past ' + duration.value.toLowerCase()))
      // dt.setHours(0)
      // for (const { date, count } of data) {
      //   let [month, day, year] = dt.toLocaleDateString().split('/')
      //   let [hour, minute, ampm] = dt.toLocaleTimeString().split(':')
      //   hour = parseInt(hour) + (ampm.substring(3, 5) === 'PM' && hour < 12 ? 12 : 0)
      //   let dtString = year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day + ' ' + hour + ':' + minute
      //   while (dtString < date) {
      //     if (dtString.substring(0, 10) !== setDate) {
      //       set++
      //       setDate = dtString.substring(0, 10)
      //       let borderColor = ''
      //       if (set < colors.length) borderColor = colors[set]
      //       else borderColor = colors[set % colors.length]
      //       chartData.datasets.push({ label: setDate, data: [], borderColor })
      //     }
      //     console.log(dtString, date)
      //     chartData.datasets[set].data.push(0)
      //     dt.setHours(dt.getHours() + 1)
      //     let [month, day, year] = dt.toLocaleDateString().split('/')
      //     let [hour, minute, ampm] = dt.toLocaleTimeString().split(':')
      //     hour = parseInt(hour) + (ampm.substring(3, 5) === 'PM' && hour < 12 ? 12 : 0)
      //     dtString = year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day + ' ' + hour + ':' + minute
      //   }
      //   chartData.labels.push(date)
      //   chartData.datasets[set].data.push(count)
      //   dt.setHours(dt.getHours() + 1)
      // }
    }
    watch(view, loadData)

    function loadData () {
      if (view.value === 'chart') loadChart()
      else loadScans()
    }

    function clearDates () {
      filter.value.dates = []
      loadData()
    }

    return {
      user,
      filter,
      dateRangeText,
      tableOptions,
      timeclockList,
      studentList,
      scans,
      itemTotal,
      loadingScans,
      loadScans,
      yearMonDayFormat,
      monitorMode,
      monitorScans,
      clearLocation,
      view,
      chartOptions,
      chartData,
      loadChart,
      loadData,
      clearDates
    }
  }
}
</script>
